import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'

interface IProps {
    nameTratamiento: string
    nameAceptacionDatosPersonales: string
    children?: React.ReactNode
}

const Container = styled.div`
    display: block
`

const Component = styled.input`
    width: 15px;
    height: 15px;
    visibility: visible;
    position: absolute;
    opacity: 0;
    margin: 3px 0px 0px;
    border: 1px solid #284fab;
`

const Label = styled.label`
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    position: relative;
    padding-left: 30px;
    margin-top: 0px;
    display: inline-block;
    cursor: pointer;
    & a {
        color: #5a85c4;
        font-weight: 800;
        text-decoration: underline;
    }
    ${media.sm`
        padding-top: 3px;
        padding-bottom: 3px;
    `}
`
const B = styled.b`
     color: #5a85c4;
     text-decoration: underline;
`
const Small = styled.small`
    width: 15px;
    height: 15px;
    left: 0px;
    top: 3px;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    border: 1px solid #284fab;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
        background: #782cbf;
        border-color: #284fab;
        &::after {
            content: '';
            width: 8px;
            height: 3px;
            display: block;
            border: 2px solid #fff;
            transform: rotate(-45deg);
            border-right: 0;
            border-top: 0;
            position: absolute;
            left: 0;
            top: -1px;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
`

const AceptacionDatosPersonales = ({
    nameTratamiento,
    nameAceptacionDatosPersonales,
}: IProps) => {

    const { register, watch } = useFormContext()

    const inputWatch = watch(nameTratamiento) ?? ''
    const inputWatchAcepDatos = watch(nameAceptacionDatosPersonales) ?? ''

    return (
        <>
        <Container>
            <Component
                {...(nameAceptacionDatosPersonales && register(nameAceptacionDatosPersonales))}
                id={`checkbox-${nameAceptacionDatosPersonales}`}
                type='checkbox'
                name={nameAceptacionDatosPersonales}
            />
            <Label htmlFor={`checkbox-${nameAceptacionDatosPersonales}`}>
                <Small className={inputWatchAcepDatos ? 'active' : ''} />
                He leído y acepto el <a target='_blank' rel='noreferrer' href='https://prduncblobstorage.blob.core.windows.net/derechosarco/TratamientoDatosPersonales-Departamentos.pdf'><B>Tratamiento de mis datos personales.</B></a> 
            </Label>
        </Container>
        <Container>
            <Component
                {...(nameTratamiento && register(nameTratamiento))}
                id={`checkbox-${nameTratamiento}`}
                type='checkbox'
                name={nameTratamiento}
            />
            <Label htmlFor={`checkbox-${nameTratamiento}`}>
                <Small className={inputWatch ? 'active' : ''} />
                He leído y acepto el <a target='_blank' href='https://prduncblobstorage.blob.core.windows.net/derechosarco/ClausulaEnvioComunicacionesComerciales-Departamentos.pdf'><B>Tratamiento de mis datos personales para el envío de comunicaciones comerciales.</B></a>
            </Label>
        </Container>
        </>
    )
}

export default AceptacionDatosPersonales